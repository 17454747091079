import axios from "axios";
import consts from "../consts";
import { setError } from "../common/toast/toast";
import DateFormat from "../common/dateFormat/DateFormat";

export function setModoTela(modo) {
  return async (dispatch, getState) => {
    dispatch({
      type: "DASHBOARD_MODO_TELA",
      payload: {
        modo: modo,
      },
    });
  };
}

export function setDataPrecipitacao(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: "SET_DATA_PRECIPITACAO_DASHBOARD",
      payload: data,
    });
  };
}

export function setDataMinMax(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: "SET_DATA_MIN_MAX_DASHBOARD",
      payload: data,
    });
  };
}
export function setDataPrevisao(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: "SET_DATA_PREVISAO_DASHBOARD",
      payload: data,
    });
  };
}

export function getDados() {
  return async (dispatch, getState) => {
    let data = DateFormat.getDataAtual().replace(/\//g, "-");
    const dataPrecipitacao = getState().dashboard.dataPrecipitacao || null;
    const dataMinMax = getState().dashboard.dataMinMax || null;
    try {
      const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado || {};

      if (!dispositivoSelecionado.serie) {
        setError("Nenhum dispositivo selecionado");
        return {};
      }
      const url = `${consts.API_URL}/estacao/dashboard?serial=${dispositivoSelecionado.serie}&dataPrecipitacao=${dataPrecipitacao}&dataMinMax=${dataMinMax}`;
      const resp = await axios.get(url);

      if (resp.data.data_hora) {
        const dataHora = DateFormat.formatarDataHoraSqlParaTela(resp.data.data_hora); // 17/02/2025 13:05
        data = dataHora.split(" ")[0].split("/").reverse().join("-");
      }

      dispatch({
        type: "DASHBOARD_LISTADO",
        payload: resp,
      });
    } catch (e) {
      setError("Controladora não respondeu");

    } finally {
      if (!dataPrecipitacao) dispatch(setDataPrecipitacao(data));
      if (!dataMinMax) dispatch(setDataMinMax(data));

    }
  };
}

export function getPrevisaoTempo() {
  return async (dispatch, getState) => {
    const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado || {};
    const dataPrevisao = getState().dashboard.dataPrevisao || null;

    if (!dataPrevisao) dispatch(setDataPrevisao(DateFormat.getDataAtual().replace(/\//g, "-")));

    if (!dispositivoSelecionado.latitude || !dispositivoSelecionado.latitude) {
      setError("Não foi possível obter a localização do dispositivo");
      return {};
    }
    const url = `${consts.API_URL}/estacao/previsaoTempo?${dispositivoSelecionado.latitude ? "lat=" + dispositivoSelecionado.latitude : ""}${
      dispositivoSelecionado.longitude ? "&lon=" + dispositivoSelecionado.longitude : ""
    }${dataPrevisao ? "&data=" + dataPrevisao : ""}`;

    try {
      const previsao = await axios.get(url);
      dispatch({
        type: "SET_PREVISAO_TEMPO_DASHBOARD",
        payload: previsao,
      });
    } catch (erro) {
      setError("Não foi possível obter a previsão do tempo");
    }
  };
}
