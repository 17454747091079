/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
	ferrugemAsiaticaSoja:'Indefinida',
	ferrugemPolissoraMilho:'Indefinida',
	brusoneTrigo:'Indefinida',
	brusoneArroz:'Indefinida'
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "PRESSAO_DOENCAS_LISTADO":
			return {
				...state,
				ferrugemAsiaticaSoja:action.payload.data.ferrugem_asiatica_soja,
				ferrugemPolissoraMilho:action.payload.data.ferrugem_polissora_milho,
				brusoneTrigo:action.payload.data.brusone_trigo,
				brusoneArroz:action.payload.data.brusone_arroz,
			};

		default:
			return state;
	}
};
