/* eslint-disable import/no-anonymous-default-export */
import DateFormat from "../common/dateFormat/DateFormat";
const INITIAL_STATE = {
  aguardando: false,
  previsaoTempo: {},
  periodoGrafico: "dia",
  lista: [],
  totais: {},
  serieGrafico: "temperatura",
  dataGrafico: DateFormat.getDataAtual().replace(/\//g, "-"),
  dataInicialGrafico: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay()).toISOString().substring(0, 10),
  dataFinalGrafico: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (6 - new Date().getDay())).toISOString().substring(0, 10),
  dataLimiteSemanaAtual: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (6 - new Date().getDay()))
    .toISOString()
    .substring(0, 10),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "HISTORICO_SET_TIPO_GRAFICO":
      return {
        ...state,
        periodoGrafico: action.payload,
      };
    case "HISTORICO_SET_AGUARDANDO":
      return { ...state, aguardando: action.payload };

    case "HISTORICO_SET_DATA_GRAFICO":
      return {
        ...state,
        dataGrafico: action.payload.dataGrafico || state.dataGrafico,
        dataInicialGrafico: action.payload.dataInicialGrafico || state.dataInicialGrafico,
        dataFinalGrafico: action.payload.dataFinalGrafico || state.dataFinalGrafico,
      };

    case "HISTORICO_LISTADO":
      return {
        ...state,
        lista: action.payload.data.historico,
        totais: action.payload.data.totais,
      };

    case "HISTORICO_SET_PREVISAO_TEMPO":
      return {
        ...state,
        previsaoTempo: action.payload.data,
      };

    case "HISTORICO_SET_SERIE_GRAFICO":
      return {
        ...state,
        serieGrafico: action.payload,
      };

    default:
      return state;
  }
};
