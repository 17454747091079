/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Avatar, Grid, Typography, Card, CardActionArea, CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";

export default (props) => {
  let color = "#0073b6";
  let colorDark = "#015c93";
  let colorLight = "";

  switch (props.color) {
    case "blue":
      color = "#3b83ad";
      colorDark = "#015C93";
      break;
    case "grayBlue":
      color = "#7798bf";
      colorDark = "#46628d";

      break;

    case "lightBlue":
      color = "#40adeb";
      colorDark = "#0073b6";
      break;

    case "extraLightBlue":
      color = "#8fd5f7";
      colorDark = "#65bfff";
      break;

    case "grayGreen":
      color = "#bcdbcd";
      colorDark = "#90c5be";
      break;

    case "purple":
      color = "#605ca8";
      colorDark = "#4e4a85";
      colorLight = "#f6ebff";
      break;

    case "orange":
      color = "#ffb457";
      colorDark = "#f99a38";
      break;

    case "green":
      color = "#4ba9b3";
      colorDark = "#00838f";
      break;

    case "sickGreen":
      color = "#5acf99";
      colorDark = "#2ba572";
      break;

    case "leafGreen":
      color = "#91d780";
      colorDark = "#73bd5b";
      break;
    case "darkLeafGreen":
      color = "#68bb72";
      colorDark = "#49af51";
      break;

    case "lightGreen":
      color = "#4db6ac";
      colorDark = "#00867d";
      break;

    case "darkGreen":
      color = "#009688";
      colorDark = "#00675b";
      colorLight = "#e0f2f1";
      break;

    case "yellow":
      color = "#fdd475";
      colorDark = "#ffc751";
      break;

    case "red":
      color = "#ef5350";
      colorDark = "#b61827";
      break;

    default:
  }

  let style = {
    cardContent: {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      minHeight: 95,
      height: props.height,
      backgroundColor: color,
    },
    contentIcon: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: colorDark,
      justifyContent: "center",
      padding: 2,
    },
    contentTitle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: 4,
      paddingTop: 12,
      paddingRight: 4,
      paddingBottom: 4,
    },
    title: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: 16,
    },
    subtitle: {
      color: "#fff",
      fontSize: 16,
    },
    icon: {
      color: "#fff",
      backgroundColor: colorDark,
    },
    contentComponent: {
      background: colorLight,
      justifyContent: "center",
      height: props.height,
      padding: 0,
      paddingTop: props.paddingtop || 0,
    },
  };

  const content = (
    <>
      <CardContent style={style.cardContent}>
        <div style={style.contentIcon}>
          <Avatar variant="square" style={style.icon}>
            {props.icon}
          </Avatar>
        </div>
        <div style={style.contentTitle}>
          <Typography gutterTop style={style.title}>
            {props.title}
          </Typography>
          <Typography gutterBottom style={style.subtitle}>
            {props.subtitle}
          </Typography>
        </div>
        {props.component && props.componentInside ? props.component : null}
      </CardContent>
      {props.component && !props.componentInside ? <CardContent style={style.contentComponent}>{props.component}</CardContent> : null}
    </>
  );

  return (
    <Grid item xs={props.xs} xl={props.xl} sm={props.sm} md={props.md} lg={props.lg}>
      <Card style={{ boxShadow: "#aaa 2px 2px 5px" }}>
        {props.path ? (
          <CardActionArea component={Link} to={props.path}>
            {content}
          </CardActionArea>
        ) : (
          content
        )}
      </Card>
    </Grid>
  );
};
