import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import { Redirect } from "react-router";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import { MapLeaflet } from "../common/components/MapLeaflet";

import { setModoTela, initForm } from "./dispositivoActions";
import { AddPhotoAlternate, RemoveCircleOutline } from "@material-ui/icons";
import { setInfo } from "../common/toast/toast";

class DispositivoForm extends Component {
  componentWillMount() {
    this.props.initForm({ ...this.props.registro, alterarIncluirTabelaDispositivoUsuario: !this.props.sessao.administrador });
  }
  componentDidMount() {
    if (this.props.formularioValues.usuario_principal_logado) setInfo("Confira as coordenadas da estação");
    const fileSelectorImagem = document.createElement("input");
    fileSelectorImagem.setAttribute("type", "file");
    fileSelectorImagem.addEventListener("change", (data) => {
      let reader = new FileReader();
      let file = data.target.files[0];
      if (file) {
        if (!file.name.match(/.png|.jpg|.jpeg/gi)) {
          alert("Selecione apenas imagens (.PNG, .JPG e .JPEG)");
          return;
        }

        reader.onloadend = () => {
          this.props.initForm({
            ...this.props.formularioValues,
            imagem_logo: reader.result,
          });
        };
        reader.readAsDataURL(file);
      }
    });

    this.fileSelectorImagem = fileSelectorImagem;
  }

  render() {
    if (!this.props.formularioValues) return <Redirect to="/" />;
    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            {this.props.formularioValues.usuario_principal_logado ? (
              <div style={{ border: "1px solid #ddd", borderRadius: 10, padding: 20, margin: "10px 0px" }}>
                <div className="d-flex align-items-center" style={{ maxWidth: 250 }}>
                  <div
                    className="border justify-content-center w-100 border-danger text-danger rounded btn fileinput-button dz-clickable mb-3"
                    style={{ display: this.props.formularioValues.imagem_logo ? "flex" : "none" }}
                    onClick={(e) => this.props.initForm({ ...this.props.formularioValues, imagem_logo: null })}
                    title="Remover Logo"
                  >
                    <div style={{ display: "flex", gap: 10, fontSize: "1.2rem" }}>
                      <span>Remover Logo</span> <RemoveCircleOutline />
                    </div>
                  </div>
                  <div
                    className="border justify-content-center w-100 border-info text-info rounded btn fileinput-button dz-clickable mb-3"
                    style={{ display: this.props.formularioValues.imagem_logo ? "none" : "flex" }}
                    onClick={(e) => this.fileSelectorImagem.click()}
                    title="Adicionar Logo"
                  >
                    <div className="bordered" style={{ display: "flex", gap: 10, fontSize: "1.2rem" }}>
                      <span>Adicionar Logo</span> <AddPhotoAlternate />
                    </div>
                  </div>
                </div>
                {this.props.formularioValues.imagem_logo ? (
                  <div
                    style={{
                      height: 100,
                      maxWidth: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={this.props.formularioValues.imagem_logo}
                      alt="Imagem da logo"
                      style={{
                        objectFit: "contain",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 100,
                    }}
                  >
                    <i
                      className="fas fa-image"
                      style={{
                        fontSize: 100,
                        color: "#777",
                      }}
                    ></i>
                  </div>
                )}
              </div>
            ) : null}
            <Field readOnly={true} name="tipo_nome" component={LabelAndInput} label="Tipo" placeholder="Informe o Tipo" cols="12 12 12 12" />
            <Field readOnly={true} name="serie" component={LabelAndInput} label="Série" placeholder="Informe a Série" cols="12 12 12 12" />
            <Field
              name={this.props.sessao.administrador ? "nome_administrador" : this.props.formularioValues.nome_dispositivo ? "nome_dispositivo" : "nome"}
              component={LabelAndInput}
              label="Nome"
              placeholder="Informe o Nome"
              cols="12 12 12 12"
            />
            {this.props.formularioValues.usuario_principal_logado ? (
              <>
                <h6
                  style={{
                    padding: "20px 0px 5px 0px",
                    color: "#0000008a",
                    fontWeight: 700,
                  }}
                >
                  Informe a Localização da Estação (clique na posição para marcar)
                </h6>
                <Grid cols="12 12 12 12" style={{ height: 500 }}>
                  <MapLeaflet
                    readOnly={!this.props.formularioValues.usuario_principal_logado}
                    coordenadas={{
                      latitude: this.props.formularioValues.latitude,
                      longitude: this.props.formularioValues.longitude,
                    }}
                    onChange={(coordenadas) => {
                      this.props.initForm({
                        ...this.props.formularioValues,
                        latitude: coordenadas.latitude,
                        longitude: coordenadas.longitude,
                      });
                    }}
                  />
                </Grid>
              </>
            ) : null}
          </ContentCardBody>
          <ContentCardFooter style={{ height: 200 }}>
            <Row alignCenter>
              <Grid cols="6 6 4 3">
                <Button text="Salvar" submit type={"success"} icon={"fa fa-check"} />
              </Grid>
              <Grid cols="6 6 4 3">
                <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => (window.location = "#/")} />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }
}

DispositivoForm = reduxForm({
  form: "dispositivoForm",
  destroyOnUnmount: true,
})(DispositivoForm);
const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  registro: state.dispositivo.registro,
  formularioValues: getFormValues("dispositivoForm")(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DispositivoForm);
