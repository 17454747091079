import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, CssBaseline, Grid } from "@material-ui/core";

import logoDoencas from "../assets/images/pressao_doencas.png";
import trianguloDoencas from "../assets/images/triangulo-doencas.png";

import arroz from "../assets/images/rice.png";
import trigo from "../assets/images/wheat-plant.png";
import soja from "../assets/images/soybean.png";
import milho from "../assets/images/corn.png";

import { getDados } from "./pressaoDoencasActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";
import Table from "../common/table/table";
import Tr from "../common/table/tr";
import TBody from "../common/table/tBody";
import THead from "../common/table/tHead";
import Th from "../common/table/th";
import Td from "../common/table/td";
import { Flag } from "@material-ui/icons";
import { PublishedWithChangesRounded } from "@mui/icons-material";

class PressaoDoencas extends Component {
  componentDidMount() {
    this.props.getListaDispositivo(this.props.sessao.id || 0);
    if (this.props.dispositivoSelecionado) {
      this.props.getDados();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.listaDispositivos) !== JSON.stringify(this.props.listaDispositivos) ||
      JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado)
    ) {
      this.props.getDados();
    }
  }
  getLocalStyles() {
    return {
      root: {
        display: "flex",
        justifyContent: "center",
        padding: 10,
        paddingBottom: 100,
      },
    };
  }

  render() {
    const style = this.getLocalStyles();

    let dataLimitePrevisao = new Date();
    dataLimitePrevisao.setDate(dataLimitePrevisao.getDate() + 7);
    dataLimitePrevisao = dataLimitePrevisao.toISOString().substring(0, 10);

    const colorFlag = {
      Alto: "#ea2929",
      Atenção: "#ffeb38",
      Neutro: "#ffeb38",
      Baixo: "#4caf50",
      Indefinido: "#CCC",
    };
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={1} style={style.root}>
          <Grid item xs={12} xl={12} sm={10} md={8} lg={8}>
            <Card style={{ boxShadow: "#aaa 2px 2px 5px", fontSize: "1rem" }}>
              <div className="d-flex justify-content-end m-4">
                <h2 style={{ color: "#4db6ac" }}>
                  <PublishedWithChangesRounded fontSize="large" /> Experimental
                </h2>
              </div>
              <div className="d-flex flex-column align-items-center m-4">
                <img src={logoDoencas} height={100} alt=""></img>
              </div>
              <Table maxHeight="100%" overflowY="hidden">
                <THead>
                  <Tr>
                    <Th padding={10}>Doença</Th>
                    <Th padding={10}>Risco de Incidência</Th>
                  </Tr>
                </THead>
                <TBody>
                  <Tr>
                    <Td style={{ display: "flex" }} padding={10}>
                      <img className="mr-2" src={soja} height={30} alt="Ferrugem Asiática na Soja" />
                      Ferrugem Asiática na Soja
                    </Td>
                    <Td padding={10}>
                      <Flag fontSize="large" style={{ marginRight: 20, color: colorFlag[this.props.ferrugemAsiaticaSoja] }} /> {this.props.ferrugemAsiaticaSoja}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td style={{ display: "flex" }} padding={10}>
                      <img className="mr-2" src={milho} height={30} alt="Ferrugem Polissora no Milho" />
                      Ferrugem Polissora no Milho
                    </Td>
                    <Td padding={10}>
                      <Flag fontSize="large" style={{ marginRight: 20, color: colorFlag[this.props.ferrugemPolissoraMilho] }} />
                      {this.props.ferrugemPolissoraMilho}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td style={{ display: "flex" }} padding={10}>
                      <img className="mr-2" src={arroz} height={30} alt="Brusone no Arroz" /> Brusone no Arroz
                    </Td>
                    <Td padding={10}>
                      <Flag fontSize="large" style={{ marginRight: 20, color: colorFlag[this.props.brusoneArroz] }} />
                      {this.props.brusoneArroz}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td style={{ display: "flex" }} padding={10}>
                      <img className="mr-2" src={trigo} height={30} alt="Brusone no Trigo" />
                      Brusone no Trigo
                    </Td>
                    <Td padding={10}>
                      <Flag fontSize="large" style={{ marginRight: 20, color: colorFlag[this.props.brusoneTrigo] }} />
                      {this.props.brusoneTrigo}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td style={{ display: "flex" }} padding={10}>
                      El Niño
                    </Td>
                    <Td padding={10}>
                      <Flag fontSize="large" style={{ marginRight: 20, color: colorFlag["Neutro"] }} />
                      Neutro
                    </Td>
                  </Tr>
                  <Tr>
                    <Td style={{ display: "flex" }} padding={10}>
                      La Niña
                    </Td>
                    <Td padding={10}>
                      <Flag fontSize="large" style={{ marginRight: 20, color: colorFlag["Neutro"] }} />
                      Neutro
                    </Td>
                  </Tr>
                </TBody>
              </Table>
              <div className="px-3 pb-3">
                <b>*Dados CPTEC/INPE</b>
              </div>
            </Card>
            <Card style={{ boxShadow: "#aaa 2px 2px 5px", fontSize: "1.1rem", textAlign: "justify" }}>
              <div className="d-flex flex-column align-items-center">
                <img className="m-5" src={trianguloDoencas} height={200} alt=""></img>
                <ul className="pr-3">
                  <li>
                    <h6>Se o ambiente for ideal, a estação fará automaticamente a alteração do risco de incidência: Baixo, Atenção ou Alto.</h6>
                  </li>
                  <li>
                    <h6>
                      Se o seu plantio for trigo, o ambiente for ideal (dados estação) e a presença da doença estiver na atmosfera, logo estes seriam os 3
                      pontos do triângulo completo, para o aparecimento da doença.
                    </h6>
                  </li>
                </ul>
              </div>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  listaDispositivos: state.dispositivo.lista,
  dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
  ferrugemAsiaticaSoja: state.pressaoDoencas.ferrugemAsiaticaSoja,
  ferrugemPolissoraMilho: state.pressaoDoencas.ferrugemPolissoraMilho,
  brusoneTrigo: state.pressaoDoencas.brusoneTrigo,
  brusoneArroz: state.pressaoDoencas.brusoneArroz,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDados,
      getListaDispositivo,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(PressaoDoencas);
