import React, { Component } from "react";
import { connect } from "react-redux";

import { reduxForm, getFormValues } from "redux-form";

import { bindActionCreators } from "redux";
import { MapLeaflet } from "../common/components/MapLeaflet";
import { Button, TextField, Grid } from "@material-ui/core";

import { initForm, adicionar, reset, verificarDispositivoVinculadoUsuario } from "./dispositivoUsuarioActions";
import { setError } from "../common/toast/toast";
import { AddPhotoAlternate, RemoveCircleOutline } from "@material-ui/icons";
import { Redirect } from "react-router";

class DispositivoUsuarioNovo extends Component {
  componentDidMount() {
    const fileSelectorImagem = document.createElement("input");
    fileSelectorImagem.setAttribute("type", "file");
    fileSelectorImagem.addEventListener("change", (data) => {
      let reader = new FileReader();
      let file = data.target.files[0];
      if (file) {
        if (!file.name.match(/.png|.jpg|.jpeg/gi)) {
          alert("Selecione apenas imagens (.PNG, .JPG e .JPEG)");
          return;
        }

        reader.onloadend = () => {
          this.props.initForm({
            ...this.props.formularioValues,
            imagem_logo: reader.result,
          });
        };
        reader.readAsDataURL(file);
      }
    });

    this.fileSelectorImagem = fileSelectorImagem;
  }

  getLocalStyles() {
    return {
      root: {
        padding: 10,
        paddingBottom: 100,
      },
      gridInput: {
        marginTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
      },
      inputSlider: {},
      gridButton: {
        marginTop: 20,
      },
    };
  }

  render() {
    if (!this.props.formularioValues) return <Redirect to="/" />;

    let style = this.getLocalStyles();

    return (
      <Grid container spacing={1} style={style.root} direction="column" justifyContent="center" alignItems="center">
        <Grid container direction="row" justifyContent="space-between" xs={12} sm={8} md={6} lg={4}>
          <Grid container style={style.gridInput} direction="column" justifyContent="center" alignItems="center">
            <Grid container direction="column" justifyContent="center">
              <TextField
                id="standard-basic"
                margin="normal"
                label="Número de Série"
                value={this.props.formularioValues.numero_serie}
                onChange={(data) => {
                  this.props.initForm({
                    ...this.props.formularioValues,
                    numero_serie: data.target.value,
                  });
                }}
              />

              {!this.props.formularioValues.id ? (
                <Grid container direction="row" justifyContent="space-between" spacing={2} style={{ marginTop: 20 }}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      fullWidth={true}
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        if (!this.props.formularioValues.numero_serie) {
                          return setError("Informe o número de série para continuar.");
                        }
                        this.props.verificarDispositivoVinculadoUsuario(this.props.formularioValues.numero_serie);
                      }}
                    >
                      Continuar
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      fullWidth={true}
                      color="dark"
                      variant="outlined"
                      onClick={() => {
                        this.props.reset();
                        window.location.href = window.location.href.replace("dispositivoUsuarioNovo", "");
                      }}
                    >
                      Voltar
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {this.props.formularioValues.usuarioPrincipalLogado ? (
                    <div style={{ border: "1px solid #ddd", borderRadius: 10, padding: 20, margin: "10px 0px" }}>
                      <div className="d-flex align-items-center">
                        <div
                          className="border justify-content-center w-100 border-danger text-danger rounded btn fileinput-button dz-clickable mb-3"
                          style={{ display: this.props.formularioValues.imagem_logo ? "flex" : "none" }}
                          onClick={(e) => this.props.initForm({ ...this.props.formularioValues, imagem_logo: null })}
                          title="Remover Logo"
                        >
                          <div style={{ display: "flex", gap: 10, fontSize: "1.2rem" }}>
                            <span>Remover Logo</span> <RemoveCircleOutline />
                          </div>
                        </div>
                        <div
                          className="border justify-content-center w-100 border-info text-info rounded btn fileinput-button dz-clickable mb-3"
                          style={{ display: this.props.formularioValues.imagem_logo ? "none" : "flex" }}
                          onClick={(e) => this.fileSelectorImagem.click()}
                          title="Adicionar Logo"
                        >
                          <div className="bordered" style={{ display: "flex", gap: 10, fontSize: "1.2rem" }}>
                            <span>Adicionar Logo</span> <AddPhotoAlternate />
                          </div>
                        </div>
                      </div>
                      {this.props.formularioValues.imagem_logo ? (
                        <div
                          style={{
                            height: 100,
                            maxWidth: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={this.props.formularioValues.imagem_logo}
                            alt="Imagem da logo"
                            style={{
                              objectFit: "contain",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 100,
                          }}
                        >
                          <i
                            className="fas fa-image"
                            style={{
                              fontSize: 100,
                              color: "#777",
                            }}
                          ></i>
                        </div>
                      )}
                    </div>
                  ) : null}
                  <TextField
                    id="standard-basic"
                    margin="normal"
                    label="Nome da Estação Meteorológica"
                    value={this.props.formularioValues.nome_controladora}
                    onChange={(data) => {
                      this.props.initForm({
                        ...this.props.formularioValues,
                        nome_controladora: data.target.value,
                      });
                    }}
                  />
                  <h6
                    style={{
                      padding: "20px 0px 5px 0px",
                      color: "#0000008a",
                      fontWeight: 700,
                    }}
                  >
                    Informe a Localização da Estação (clique na posição para marcar)
                  </h6>
                  <div style={{ width: "100%", minHeight: 300 }}>
                    <MapLeaflet
                      readOnly={!this.props.formularioValues.usuarioPrincipalLogado}
                      coordenadas={{
                        latitude: this.props.formularioValues.latitude,
                        longitude: this.props.formularioValues.longitude,
                      }}
                      onChange={(coordenadas) => {
                        this.props.initForm({
                          ...this.props.formularioValues,
                          latitude: coordenadas.latitude,
                          longitude: coordenadas.longitude,
                        });
                      }}
                    />
                  </div>
                  <Grid container direction="row" justifyContent="space-between" spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Button
                        fullWidth={true}
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                          this.props.adicionar(this.props.formularioValues);
                        }}
                      >
                        Salvar
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Button
                        fullWidth={true}
                        color="dark"
                        variant="outlined"
                        onClick={() => {
                          this.props.reset();
                          window.location.href = window.location.href.replace("dispositivoUsuarioNovo", "");
                        }}
                      >
                        Voltar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
DispositivoUsuarioNovo = reduxForm({
  form: "dispositivoUsuarioForm",
  destroyOnUnmount: true,
})(DispositivoUsuarioNovo);

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  formularioValues: getFormValues("dispositivoUsuarioForm")(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ initForm, adicionar, reset, verificarDispositivoVinculadoUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DispositivoUsuarioNovo);
