import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, CssBaseline, Grid } from "@material-ui/core";

import logoPulverizadorVerde from "../assets/images/logo_pulverizador_verde.png";
import logoPulverizadorLaranja from "../assets/images/logo_pulverizador_laranja.png";
import logoPulverizadorVermelho from "../assets/images/logo_pulverizador_vermelho.png";
import logoDoencas from "../assets/images/pressao_doencas.png";

import {
  CalendarToday as CalendarTodayIcon,
  WbSunny as WbSunnyIcon,
  SignalWifi0Bar as SemInternetIcon,
  SignalWifi1Bar as SinalRuimInternetIcon,
  SignalWifi3Bar as SinalBomInternetIcon,
  SignalWifi4Bar as SinalExcelenteInternetIcon,
  BubbleChart,
  Explore,
} from "@material-ui/icons";
import { FlashOn, WaterDrop, SwapVert, Air, FormatTextdirectionRToLRounded } from "@mui/icons-material";
import DateFormat from "../common/dateFormat/DateFormat";

import DashboardCard from "../common/components/dashboardCard";
import CardPrevisaoTempo from "../common/components/cardPrevisaoTempo";

import { setModoTela, getDados, getPrevisaoTempo, setDataPrecipitacao, setDataMinMax, setDataPrevisao } from "./dashboardActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";
import { Coronavirus } from "@mui/icons-material";
import { Agriculture } from "@mui/icons-material";
import { TouchApp } from "@material-ui/icons";

class Dashboard extends Component {
  state = {
    corBotaoAddImagem: "#ffffff",
    corBotaoRemoveImagem: "#ffffff",
  };

  componentDidMount() {
    this.props.getListaDispositivo(this.props.sessao.id || 0);
    if (this.props.dispositivoSelecionado) {
      this.props.getPrevisaoTempo();
      this.props.getDados();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.listaDispositivos) !== JSON.stringify(this.props.listaDispositivos) ||
      JSON.stringify(prevProps.dataPrecipitacao) !== JSON.stringify(this.props.dataPrecipitacao) ||
      JSON.stringify(prevProps.dataMinMax) !== JSON.stringify(this.props.dataMinMax) ||
      JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado)
    ) {
      this.props.getPrevisaoTempo();
      this.props.getDados();
    }

    const fileSelectorImagem = document.createElement("input");
    fileSelectorImagem.setAttribute("type", "file");
    fileSelectorImagem.addEventListener("change", (data) => {
      let reader = new FileReader();
      let file = data.target.files[0];
      if (file) {
        if (!file.name.match(/.png|.jpg|.jpeg/gi)) {
          alert("Selecione apenas imagens (.PNG, .JPG e .JPEG)");
          return;
        }

        reader.onloadend = () => {
          this.setState({ ...this.state, imagem_aquario: reader.result });
        };
        reader.readAsDataURL(file);
      }
    });

    this.fileSelectorImagem = fileSelectorImagem;
  }
  getLocalStyles() {
    return {
      root: {
        padding: 10,
        paddingBottom: 100,
      },
    };
  }

  render() {
    const style = this.getLocalStyles();
    const listaLogosPulverizador = {
      verde: logoPulverizadorVerde,
      laranja: logoPulverizadorLaranja,
      vermelho: logoPulverizadorVermelho,
    };
    let logoPulverizador = "";

    const temperatura = parseFloat(this.props.registro.clima_atual_temperatura);
    const umidadeAr = parseFloat(this.props.registro.clima_atual_umidade_ar);
    const vento =  parseFloat(this.props.registro.velocidade_vento);

    const condicaoVentoVerde = vento == 0 || (vento >= 3.3 && vento <= 11.9);
    const condicaoVentoLaranja = vento == 0 || (vento > 11.9 && vento <= 18.9);

    if (
      (temperatura >= 15 && temperatura <= 17.5 && umidadeAr >= 40 && condicaoVentoVerde) ||
      (temperatura > 17.5 && temperatura <= 22.5 && umidadeAr >= 50 && condicaoVentoVerde) ||
      (temperatura > 22.5 && temperatura <= 27.5 && umidadeAr >= 60 && condicaoVentoVerde) ||
      (temperatura > 27.5 && temperatura <= 32.5 && umidadeAr >= 80 && condicaoVentoVerde)
    ) {
      logoPulverizador = "verde";
    } else if (
      (temperatura >= 15 && temperatura <= 17.5 && umidadeAr >= 20 && umidadeAr < 40 && condicaoVentoLaranja) ||
      (temperatura > 17.5 && temperatura <= 20 && umidadeAr >= 20 && umidadeAr < 50 && condicaoVentoLaranja) ||
      (temperatura > 20 && temperatura <= 22.5 && umidadeAr >= 30 && umidadeAr < 50 && condicaoVentoLaranja) ||
      (temperatura > 22.5 && temperatura <= 25 && umidadeAr >= 30 && umidadeAr < 60 && condicaoVentoLaranja) ||
      (temperatura > 25 && temperatura <= 27.5 && umidadeAr >= 40 && umidadeAr < 60 && condicaoVentoLaranja) ||
      (temperatura > 27.5 && temperatura <= 30 && umidadeAr >= 40 && umidadeAr < 80 && condicaoVentoLaranja) ||
      (temperatura > 30 && temperatura <= 32.5 && umidadeAr >= 50 && umidadeAr < 80 && condicaoVentoLaranja) ||
      (temperatura > 32.5 && temperatura <= 35 && umidadeAr >= 50 && condicaoVentoLaranja)
    ) {
      logoPulverizador = "laranja";
    } else {
      logoPulverizador = "vermelho";
    }

    let dataLimitePrevisao = new Date();
    dataLimitePrevisao.setDate(dataLimitePrevisao.getDate() + 7);
    dataLimitePrevisao = dataLimitePrevisao.toISOString().substring(0, 10);

    return (
      <React.Fragment>
        <CssBaseline />

        <Grid container spacing={1} style={style.root}>
          {/* <DashboardCard
            xs={12}
            sm={6}
            md={4}
            lg={4}
            color="blue"
            icon={<WatchLaterIcon />}
            title="ÚLTIMA MENSAGEM"
            subtitle={this.props.registro.tempo_ultima_mensagem}
          /> */}

          {this.props.dispositivoSelecionado && this.props.dispositivoSelecionado.imagem_logo ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card style={{ boxShadow: "#aaa 2px 2px 5px" }} className="d-flex justify-content-center p-3">
                <img src={this.props.dispositivoSelecionado.imagem_logo} height={120} alt="logo" />
              </Card>
            </Grid>
          ) : null}

          <DashboardCard
            // height={window.innerWidth < 960 ? null : 125}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            color="orange"
            icon={<WbSunnyIcon />}
            title="CLIMA ATUAL"
            subtitle={this.props.registro.clima_atual_temperatura + "°, " + this.props.registro.clima_atual_umidade_ar + "%"}
          />
          <DashboardCard
            // height={window.innerWidth < 960 ? null : 125}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            color="grayGreen"
            icon={<Agriculture />}
            title="ÍNDICE DE APLICAÇÃO"
            component={
              <div className="w-100 d-flex justify-content-center align-items-center">
                <img src={listaLogosPulverizador[logoPulverizador]} height={60} alt="Pulverizador" />
              </div>
            }
            componentInside={true}
          />
          <DashboardCard
            // height={window.innerWidth < 960 ? null : 125}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            color="extraLightBlue"
            icon={<WaterDrop />}
            title={`PRECIPITAÇÃO`}
            subtitle={String(this.props.registro.precipitacao_dia).replace(".", ",") + " mm"}
            component={
              <div className="d-flex flex-column pt-4" style={{ color: "#225675" }}>
                <input
                  style={{
                    fontSize: "1rem",
                    padding: 7,
                    border: "1px solid #EEE",
                    borderRadius: 8,
                    height: 25,
                  }}
                  required
                  type="date"
                  value={this.props.dataPrecipitacao}
                  max={DateFormat.getDataAtual().replace(/\//g, "-")}
                  onFocus={(e) => (e.target.style.outline = "none")}
                  onChange={(e) => {
                    if(!e.target.value) return;
                    const valorData = e.target.value;
                    this.props.setDataPrecipitacao(valorData);
                  }}
                ></input>
                <div>
                  <b>Acumulado mês:</b> {String(this.props.registro.precipitacao_mes).replace(".", ",") + " mm"}
                </div>
                <div>
                  <b>Acumulado semana:</b> {String(this.props.registro.precipitacao_semana).replace(".", ",") + " mm"}
                </div>
              </div>
            }
            componentInside={FormatTextdirectionRToLRounded}
          />
          <DashboardCard
            height={window.innerWidth < 960 ? null : 150}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            color="red"
            icon={<SwapVert />}
            title={`MÍNIMAS E MÁXIMAS`}
            subtitle={
              <>
                <p
                  style={{
                    margin: window.innerWidth < 960 ? 0 : "10px 0 5px 0",
                  }}
                >
                  {`${this.props.registro.temperatura_ar_minima || ""}°C - ${this.props.registro.temperatura_ar_maxima || ""}°C`}
                </p>
                <p style={{ margin: window.innerWidth < 960 ? 0 : null }}>
                  {`${this.props.registro.umidade_ar_minima || ""}% - ${this.props.registro.umidade_ar_maxima || ""}%`}
                </p>
              </>
            }
            component={
              <div className="d-flex flex-column justify-content-center pl-4" style={{ color: "#225675" }}>
                <input
                  style={{
                    fontSize: "1rem",
                    padding: 7,
                    border: "1px solid #EEE",
                    borderRadius: 8,
                    height: 25,
                  }}
                  required
                  type="date"
                  value={this.props.dataMinMax}
                  max={DateFormat.getDataAtual().replace(/\//g, "-")}
                  onFocus={(e) => (e.target.style.outline = "none")}
                  onChange={(e) => {
                    if(!e.target.value) return;
                    const valorData = e.target.value;
                    this.props.setDataMinMax(valorData);
                  }}
                ></input>
              </div>
            }
            componentInside={FormatTextdirectionRToLRounded}
          />
          <CardPrevisaoTempo
            xs={12}
            sm={12}
            md={8}
            lg={4}
            width="100%"
            previsao={this.props.registro.previsao}
            component={
              <div className="d-flex flex-column justify-content-center pl-4" style={{ color: "#225675" }}>
                <input
                  style={{
                    fontSize: "1rem",
                    padding: 7,
                    border: "1px solid #EEE",
                    borderRadius: 8,
                    height: 25,
                  }}
                  required
                  type="date"
                  value={this.props.dataPrevisao}
                  min={DateFormat.getDataAtual().replace(/\//g, "-")}
                  max={dataLimitePrevisao}
                  onFocus={(e) => (e.target.style.outline = "none")}
                  onChange={(e) => {
                    if(!e.target.value) return;
                    const valorData = e.target.value;
                    this.props.setDataPrevisao(valorData);
                    this.props.getPrevisaoTempo();
                  }}
                ></input>
              </div>
            }
          />
          <DashboardCard
            height={window.innerWidth < 1280 ? null : 150}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            color="sickGreen"
            path="pressaoDoencas"
            icon={<Coronavirus />}
            title="PRESSÃO DE DOENÇAS"
            subtitle={   <div><TouchApp/> Clique aqui</div>}
            component={
              <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                <img src={logoDoencas} height={60} alt="Pressão de Doença" />
              </div>
            }
            componentInside={true}
          />

          <DashboardCard
            xs={12}
            sm={12}
            md={4}
            lg={4}
            color="grayBlue"
            icon={<CalendarTodayIcon />}
            title="ÚLTIMA LEITURA"
            subtitle={this.props.registro.data_hora}
          />

          <DashboardCard
            // height={window.innerWidth < 960 ? null : 150}
            xs={6}
            sm={6}
            md={2}
            lg={2}
            color="leafGreen"
            icon={<Air />}
            title="VELOCIDADE DO VENTO"
            subtitle={this.props.registro.velocidade_vento + " km/h"}
          />
          <DashboardCard
            // height={window.innerWidth < 960 ? null : 150}
            xs={6}
            sm={6}
            md={2}
            lg={2}
            color="darkLeafGreen"
            icon={<Explore />}
            title="DIREÇÃO DO VENTO"
            subtitle={this.props.registro.direcao_vento}
          />
          <DashboardCard
            // height={window.innerWidth < 960 ? null : 150}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            color="lightBlue"
            icon={<BubbleChart />}
            title="UMIDADE DO SOLO"
            subtitle={this.props.registro.umidade_solo + "%"}
          />

          <DashboardCard
            xs={12}
            sm={6}
            md={4}
            lg={4}
            color="yellow"
            icon={<FlashOn />}
            title={`ESTADO DA BATERIA`}
            subtitle={this.props.registro.estado_bateria.percentualBateria + "% " + this.props.registro.estado_bateria.alerta}
          />

          <DashboardCard
            xs={12}
            sm={6}
            md={4}
            lg={4}
            color="green"
            icon={
              !this.props.registro.rssi ? (
                <SemInternetIcon />
              ) : this.props.registro.rssi == "Ruim" ? (
                <SinalRuimInternetIcon />
              ) : this.props.registro.rssi == "Bom" ? (
                <SinalBomInternetIcon />
              ) : (
                <SinalExcelenteInternetIcon />
              )
            }
            title="SINAL WI-FI"
            subtitle={`${this.props.registro.rssi || "Estação inacessível"} - ${
              this.props.registro.intermediario !== "00000000" ? "Intermediário: " + this.props.registro.intermediario : this.props.registro.serial
            }`}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  modoTela: state.dashboard.modoTela,
  listaDispositivos: state.dispositivo.lista,
  dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
  registro: state.dashboard.registro,
  dataPrecipitacao: state.dashboard.dataPrecipitacao,
  dataMinMax: state.dashboard.dataMinMax,
  dataPrevisao: state.dashboard.dataPrevisao,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModoTela,
      setDataPrecipitacao,
      setDataMinMax,
      setDataPrevisao,
      getDados,
      getPrevisaoTempo,
      getListaDispositivo,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
