import { initialize } from "redux-form";
import axios from "axios";
import consts from "../consts";

export function initForm(registro = {}) {
  return [initialize("authForm", registro)];
}

export function login(values) {
  return submit(
    {
      ...values,
      administrador: false,
    },
    `${consts.OAPI_URL}/usuario/login`
  );
}

export function signup(values) {
  return submit(values, `${consts.OAPI_URL}/usuario/signup`);
}

function submit(values, url) {
  return (dispatch) => {
    //quando um novo usuário for se cadastrar é obrigatório informar um telefone
    if (!values.telefone && url === `${consts.OAPI_URL}/usuario/signup`) {
      return dispatch([
        {
          type: "USER_MESSEGE",
          payload: {
            open: true,
            text: "Informe o número de telefone",
            time: 5000,
            type: "error",
          },
        },
      ]);
    }
    axios
      .post(url, values)
      .then((resp) => {
        dispatch([{ type: "USER_FETCHED", payload: resp.data }]);
      })
      .catch((e) => {
        if (e.response && e.response.status == 400) {
          if (e.response.data instanceof Array) {
            e.response.data.forEach((retorno) => {
              dispatch([
                {
                  type: "USER_MESSEGE",
                  payload: {
                    open: true,
                    text: retorno.msg ? retorno.msg : retorno,
                    time: 5000,
                    type: "error",
                  },
                },
              ]);
              setTimeout(() => {
                dispatch([
                  {
                    type: "USER_MESSEGE",
                    payload: {
                      open: false,
                      text: "",
                      time: 5000,
                      type: "error",
                    },
                  },
                ]);
              }, 6000);
            });
          } else {
            dispatch([
              {
                type: "USER_MESSEGE",
                payload: {
                  open: true,
                  text: e.response.data,
                  time: 5000,
                  type: "error",
                },
              },
            ]);
            setTimeout(() => {
              dispatch([
                {
                  type: "USER_MESSEGE",
                  payload: {
                    open: false,
                    text: "",
                    time: 5000,
                    type: "error",
                  },
                },
              ]);
            }, 6000);
          }
        } else {
          dispatch([
            {
              type: "USER_MESSEGE",
              payload: {
                open: true,
                text: "Erro ao realizar Operação!!",
                time: 5000,
                type: "error",
              },
            },
          ]);
          setTimeout(() => {
            dispatch([
              {
                type: "USER_MESSEGE",
                payload: {
                  open: false,
                  text: "",
                  time: 5000,
                  type: "error",
                },
              },
            ]);
          }, 6000);
        }
      });
  };
}

export function logout() {
  window.location.reload();
  return { type: "TOKEN_VALIDATED", payload: false };
}

export function validateToken(token) {
  return async (dispatch) => {
    try {
      if (!token) dispatch({ type: "TOKEN_VALIDATED", payload: false });
      dispatch({ type: "USER_SET_AGUARDANDO", payload: true });
      const resp = await axios.post(`${consts.OAPI_URL}/usuario/validateToken`, { token });
      dispatch({ type: "TOKEN_VALIDATED", payload: resp.data.valid });
    } catch (e) {
      dispatch({ type: "TOKEN_VALIDATED", payload: false });
    } finally {
      dispatch({ type: "USER_SET_AGUARDANDO", payload: false });
    }
  };
}

export function recuperarSenha(values, evento) {
  return (dispatch) => {
    axios
      .post(`${consts.OAPI_URL}/usuario/recuperarSenha`, values)
      .then((resp) => {
        //se veio array é pq o usuario já alterou a senha
        //então manda pra tela de login e atualiza a pagina
        if (resp.data instanceof Array) {
          evento(resp.data);
          setTimeout(() => window.location.reload(), 2000);
        } else {
          //se veio {} é pq está obtendo o código e os dados do usuário
          evento(resp.data);
          dispatch(initForm({ codigo: null }));
        }
      })
      .catch((e) => {
        // console.log(e);
        if (e.response.status == 400) {
          if (e.response.data instanceof Array) {
            e.response.data.forEach((retorno) => {
              dispatch([
                {
                  type: "USER_MESSEGE",
                  payload: {
                    open: true,
                    text: retorno.msg ? retorno.msg : retorno,
                    time: 5000,
                    type: "error",
                  },
                },
              ]);
              setTimeout(() => {
                dispatch([
                  {
                    type: "USER_MESSEGE",
                    payload: {
                      open: false,
                      text: "",
                      time: 5000,
                      type: "error",
                    },
                  },
                ]);
              }, 6000);
            });
          } else {
            dispatch([
              {
                type: "USER_MESSEGE",
                payload: {
                  open: true,
                  text: e.response.data,
                  time: 5000,
                  type: "error",
                },
              },
            ]);
            setTimeout(() => {
              dispatch([
                {
                  type: "USER_MESSEGE",
                  payload: {
                    open: false,
                    text: "",
                    time: 5000,
                    type: "error",
                  },
                },
              ]);
            }, 6000);
          }
        } else {
          dispatch([
            {
              type: "USER_MESSEGE",
              payload: {
                open: true,
                text: "Erro ao realizar Operação!!",
                time: 5000,
                type: "error",
              },
            },
          ]);
          setTimeout(() => {
            dispatch([
              {
                type: "USER_MESSEGE",
                payload: {
                  open: false,
                  text: "",
                  time: 5000,
                  type: "error",
                },
              },
            ]);
          }, 6000);
        }
      });
  };
}

export function validarCodigo(values, evento) {
  return (dispatch) => {
    if (values.codigo == values.codigoValido) {
      evento();
    } else {
      dispatch([
        {
          type: "USER_MESSEGE",
          payload: {
            open: true,
            text: "Código Inválido",
            time: 5000,
            type: "error",
          },
        },
      ]);
    }
  };
}

export function alterarSenha(values) {
  return (dispatch) => {
    axios
      .put(`${consts.API_URL}/usuario/alterarsenha`, values)
      .then((resp) => {
        window.location.reload();
      })
      .catch((e) => {
        // console.log(e);
        if (e.response.status == 400) {
          if (e.response.data instanceof Array) {
            e.response.data.forEach((retorno) => {
              dispatch([
                {
                  type: "USER_MESSEGE",
                  payload: {
                    open: true,
                    text: retorno.msg ? retorno.msg : retorno,
                    time: 5000,
                    type: "error",
                  },
                },
              ]);
              setTimeout(() => {
                dispatch([
                  {
                    type: "USER_MESSEGE",
                    payload: {
                      open: false,
                      text: "",
                      time: 5000,
                      type: "error",
                    },
                  },
                ]);
              }, 6000);
            });
          } else {
            dispatch([
              {
                type: "USER_MESSEGE",
                payload: {
                  open: true,
                  text: e.response.data,
                  time: 5000,
                  type: "error",
                },
              },
            ]);
            setTimeout(() => {
              dispatch([
                {
                  type: "USER_MESSEGE",
                  payload: {
                    open: false,
                    text: "",
                    time: 5000,
                    type: "error",
                  },
                },
              ]);
            }, 6000);
          }
        } else {
          dispatch([
            {
              type: "USER_MESSEGE",
              payload: {
                open: true,
                text: "Erro ao realizar Operação!!",
                time: 5000,
                type: "error",
              },
            },
          ]);
          setTimeout(() => {
            dispatch([
              {
                type: "USER_MESSEGE",
                payload: {
                  open: false,
                  text: "",
                  time: 5000,
                  type: "error",
                },
              },
            ]);
          }, 6000);
        }
      });
  };
}
