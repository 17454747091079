import axios from "axios";
import consts from "../consts";
import { setError, setSuccess } from "../common/toast/toast";

export function setPeriodoGrafico(periodo) {
  return (dispatch, getState) => {
    dispatch({
      type: "HISTORICO_SET_TIPO_GRAFICO",
      payload: periodo,
    });
    dispatch(getLista());
  };
}

export function setSerieGrafico(serie) {
  return (dispatch, getState) => {
    dispatch({
      type: "HISTORICO_SET_SERIE_GRAFICO",
      payload: serie,
    });
    dispatch(getLista());
  };
}

export function getLista() {
  return async (dispatch, getState) => {
    try {
      const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado || {};
      const nrSerie = dispositivoSelecionado.serie;
      const periodo = getState().historico.periodoGrafico;
      const data = getState().historico.dataGrafico;
      const dataInicial = getState().historico.dataInicialGrafico; //usado apenas no modo semana
      const dataFinal = getState().historico.dataFinalGrafico; //usado apenas no modo semana
      dispatch(getPrevisaoTempo(dispositivoSelecionado));
      dispatch({
        type: "HISTORICO_SET_AGUARDANDO",
        payload: true,
      });
      //ADICIONAR FILTRO NA REQUEST DO HISTORICO
      const url =
        periodo === "semana"
          ? `${consts.API_URL}/estacao/historico?serie=${nrSerie}&periodo=${periodo}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`
          : `${consts.API_URL}/estacao/historico?serie=${nrSerie}&periodo=${periodo}&data=${data}`;

      const resp = await axios.get(url);



      dispatch({
        type: "HISTORICO_LISTADO",
        payload: resp,
      });
    } catch (e) {
      // console.log(e);
      setError("Controladora não respondeu");
    } finally {
      dispatch({
        type: "HISTORICO_SET_AGUARDANDO",
        payload: false,
      });
    }
  };
}

export function setDataGrafico(registro) {
  return {
    type: "HISTORICO_SET_DATA_GRAFICO",
    payload: registro,
  };
}

export async function inativar(id) {
  return async (dispatch, getState) => {
    if (!window.confirm("Deseja realmente excluir o registro?")) return {};
    try {
      dispatch({
        type: "HISTORICO_SET_AGUARDANDO",
        payload: true,
      });

      await axios.delete(`${consts.API_URL}/estacao/historico?id=${id}`);
      setSuccess("Registro excluído com sucesso");
      await dispatch(getLista());
    } catch (e) {
      // console.log(e);
      setError("Controladora não respondeu");
    } finally {
      dispatch({
        type: "HISTORICO_SET_AGUARDANDO",
        payload: false,
      });
    }
  };
}

async function getPrevisaoTempo(dispositivoSelecionado) {
  return async (dispatch, getState) => {
    dispatch({
      type: "HISTORICO_SET_AGUARDANDO",
      payload: true,
    });
    const url = `${consts.API_URL}/estacao/previsaoTempo?${dispositivoSelecionado.latitude ? "lat=" + dispositivoSelecionado.latitude : ""}${
      dispositivoSelecionado.longitude ? "&lon=" + dispositivoSelecionado.longitude : ""
    }`;

    try {
      const previsao = await axios.get(url);
      dispatch({
        type: "HISTORICO_SET_PREVISAO_TEMPO",
        payload: previsao,
      });
      dispatch({
        type: "HISTORICO_SET_AGUARDANDO",
        payload: false,
      });
    } catch (erro) {
      setError("Não foi possível obter a previsão do tempo");
      dispatch({
        type: "HISTORICO_SET_AGUARDANDO",
        payload: false,
      });
    }
  };
}
