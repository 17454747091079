/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
  registro:{}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "DISPOSITIVO_USUARIO_SET_REGISTRO":
      return { ...state, registro: action.payload.registro };

    case "DISPOSITIVO_USUARIO_RESET":
      return INITIAL_STATE;

    default:
      return state;
  }
};
