/* eslint-disable import/no-anonymous-default-export */

const INITIAL_STATE = {
  modoTela: "dashboard",
  registro: {
    tempo_ultima_mensagem: "",
    data_hora: "",
    precipitacao: "",
    clima_atual_temperatura: "",
    clima_atual_umidade_ar: "",
    umidade_solo: "",
    velocidade_vento: "",
    direcao_vento: "",
    temperatura_ar_minima: "",
    temperatura_ar_maxima: "",
    soma_precipitacao: "",
    umidade_ar_minima: "",
    umidade_ar_maxima: "",
    intermediario: "",
    rssi: "",
    estado_bateria: { percentualBateria: 0, alerta: "" },
    previsao: {},
    serial: "",
    precipitacao_dia: "",
    precipitacao_semana: "",
    precipitacao_mes: "",
  },
  dataPrecipitacao: null,
  dataMinMax: null,
  dataPrevisao: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "DASHBOARD_MODO_TELA":
      return { ...state, modoTela: action.payload.modo };

    case "DASHBOARD_LISTADO":
      
      const dateformat = require("date-format");
      const data = action.payload.data;

      //se não encontrar nada retorna o estado anterior
      if (!data) return state;
      //se encontrar informações do dispositivo monta um objeto formatado
      const registro = {};
      const { dias, horas, minutos } = data.tempo_ultima_mensagem;
      registro.tempo_ultima_mensagem = `${dias} ${dias == 1 ? "dia" : "dias"}, ${horas} ${horas == 1 ? "hora" : "horas"}, ${minutos} ${
        minutos == 1 ? "minuto" : "minutos"
      }`;

      registro.data_hora = dateformat("dd/MM/yyyy hh:mm", new Date(data.data_hora));
      console.log(dateformat("dd/MM/yyyy hh:mm", new Date(data.data_hora)))
      registro.estado_bateria = data.bat; //objeto ex: {percentualBateria:50,alerta:''}
      registro.precipitacao = data.precpt;
      registro.clima_atual_temperatura = data.extemp;
      registro.clima_atual_umidade_ar = data.extumid;
      registro.umidade_solo = data.umidsolo;
      registro.velocidade_vento = data.velvent;
      registro.direcao_vento = data.sigla_dirvent;

      // registro.temperatura_ar_minima = data.temperatura_ar_minima;
      // registro.temperatura_ar_maxima = data.temperatura_ar_maxima;
      // registro.umidade_ar_minima = data.umidade_ar_minima;
      // registro.umidade_ar_maxima = data.umidade_ar_maxima;

      // registro.soma_precipitacao = data.soma_precipitacao;

      // vem separado para o card poder buscar por data individualmente
      registro.temperatura_ar_minima = data.min_max_dia.temperatura_ar_minima;
      registro.temperatura_ar_maxima = data.min_max_dia.temperatura_ar_maxima;
      registro.umidade_ar_minima = data.min_max_dia.umidade_ar_minima;
      registro.umidade_ar_maxima = data.min_max_dia.umidade_ar_maxima;

      // vem separado para o card poder buscar por data individualmente
      registro.precipitacao_dia = data.precipitacao_dia_semana_mes.precipitacao_dia;
      registro.precipitacao_semana = data.precipitacao_dia_semana_mes.precipitacao_semana;
      registro.precipitacao_mes = data.precipitacao_dia_semana_mes.precipitacao_mes;

      registro.intermediario = data.intermediario;
      registro.rssi = data.rssi ? (data.rssi < -70 ? "Ruim" : data.rssi <= -50 ? "Bom" : "Excelente") : "";

      registro.serial = data.serial;

      return {
        ...state,
        registro: { ...state.registro, ...registro },
      };
    case "SET_PREVISAO_TEMPO_DASHBOARD":
      return {
        ...state,
        registro: { ...state.registro, previsao: action.payload.data },
      };
    case "SET_DATA_PRECIPITACAO_DASHBOARD":
      return {
        ...state,
        dataPrecipitacao: action.payload,
      };
    case "SET_DATA_MIN_MAX_DASHBOARD":
      return {
        ...state,
        dataMinMax: action.payload,
      };
    case "SET_DATA_PREVISAO_DASHBOARD":
      return {
        ...state,
        dataPrevisao: action.payload,
      };
    case "RESETAR_DASHBOARD":
      return INITIAL_STATE;
    default:
      return state;
  }
}
