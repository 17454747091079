import axios from "axios";
import consts from "../consts";
import { setError} from "../common/toast/toast";


export function getDados() {
	return async (dispatch, getState) => {
	  try {

		const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado || {};
  
		if (!dispositivoSelecionado.serie) {
		  setError("Nenhum dispositivo selecionado");
		  return {};
		}
		const url = `${consts.API_URL}/estacao/pressaoDoencas?serial=${dispositivoSelecionado.serie}`;
		const resp = await axios.get(url);
  
		dispatch({
		  type: "PRESSAO_DOENCAS_LISTADO",
		  payload: resp,
		});
	  } catch (e) {
		setError("Controladora não respondeu");
	  }
	};
  }
