import axios from "axios";
import consts from "../consts";
import { setError, setErroAPI, setSuccess, setInfo } from "../common/toast/toast";
import { initialize } from "redux-form";

export function initForm(registro = {}) {
  return [initialize("dispositivoUsuarioForm", registro)];
}

//se já estiver vinculado a algum usuário, traz as coordenadas e não deixa editar
export function verificarDispositivoVinculadoUsuario(numero_serie) {
  return async (dispatch, getState) => {
    try {
      const resposta = await axios.get(`${consts.API_URL}/dispositivo/buscarPeloSerial?serie=${numero_serie}&id_tipo=4`);
      if (!resposta.data) {
        setError("O dispositivo não está disponível");
        return {};
      }
      const dispositivo = resposta.data;

      const usuarioLogado = getState().auth.usuario;
      const dispositivo_usuarios = dispositivo.dispositivo_usuarios || [];

      const admLogadoDispositivoAdm = usuarioLogado.administrador && usuarioLogado.id == dispositivo.id_usuario_administrador;
      const padraoLogadoDispositivoPadrao = !usuarioLogado.administrador && usuarioLogado.id == dispositivo.id_usuario;
      const padraoLogadoDispositivoTabelaNova = !usuarioLogado.administrador && dispositivo_usuarios.find((du) => du.id_usuario == usuarioLogado.id);

      if (admLogadoDispositivoAdm || padraoLogadoDispositivoPadrao || padraoLogadoDispositivoTabelaNova) {
        setError("O dispositivo já está vinculado à sua conta");
        return {};
      }

      const usuarioPrincipalLogado = !dispositivo.id_usuario && !dispositivo_usuarios.length;
      dispatch(
        initForm({
          numero_serie,
          id: dispositivo.id,
          latitude: dispositivo.latitude,
          longitude: dispositivo.longitude,
          imagem_logo: dispositivo.imagem_logo,
          usuarioPrincipalLogado: usuarioPrincipalLogado,
        })
      );
      if (usuarioPrincipalLogado) {
        setInfo("Confira as coordenadas da estação");
      }
    } catch (e) {
      setErroAPI(e);
    }
  };
}

export function reset() {
  return async (dispatch, getState) => {
    dispatch(initForm());
    dispatch({
      type: "DISPOSITIVO_USUARIO_RESET",
    });
  };
}
export function adicionar(registro) {
  return async (dispatch, getState) => {
    try {
      if (!registro.latitude || !registro.longitude) {
        setError("Clique no mapa e selecione uma coordenada para prosseguir");
        return {};
      }

      //carrega o dispositivo
      const primeiraBuscaDispositivo = await axios.get(`${consts.API_URL}/dispositivo/buscarPeloSerial?serie=${registro.numero_serie}&id_tipo=4`);

      const dispositivo = primeiraBuscaDispositivo.data || null;

      //não identificou nenhum dispositivo com o serial informado
      if (!dispositivo) {
        setError("Dispositivo não encontrado");
      } else if (getState().auth.usuario.administrador) {
        //se for um administrador logado, vincula o id_usuario_administrador
        const registroFormatado = {
          ...dispositivo,
          id_usuario_administrador: getState().auth.usuario.id,
          nome_administrador: registro.nome_controladora || registro.numero_serie,
          latitude: String(registro.latitude).substring(0, 15),
          longitude: String(registro.longitude).substring(0, 15),
          imagem_logo: registro.imagem_logo,
          alterarIncluirTabelaDispositivoUsuario: false, //administrador altera a própria tabela dispositivo
        };

        //Se é administrador permite criar testes
        await axios.put(`${consts.API_URL}/dispositivo`, registroFormatado);
        setSuccess("Operação Realizada com sucesso.");
        window.location.href = window.location.href.replace("dispositivoUsuarioNovo", "");
      } else {
        const registroFormatado = {
          ...dispositivo,
          id_usuario_dispositivo: getState().auth.usuario.id,
          nome_dispositivo: registro.nome_controladora || registro.numero_serie,
          latitude: String(registro.latitude).substring(0, 15),
          longitude: String(registro.longitude).substring(0, 15),
          imagem_logo: registro.imagem_logo,
          alterarIncluirTabelaDispositivoUsuario: true, //QUANDO VAI ESSE PARÂMETRO ALTERA NA TABELA NOVA
        };
        await axios.put(`${consts.API_URL}/dispositivo`, registroFormatado);
        setSuccess("Operação Realizada com sucesso.");
        window.location.href = window.location.href.replace("dispositivoUsuarioNovo", "");
      }
    } catch (e) {
      setErroAPI(e);
    }
  };
}
