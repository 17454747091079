import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Popup, Marker, useMapEvents } from "react-leaflet";
import "./MapLeaflet.css";

export function MapLeaflet(props) {
  const updateForm = props.onChange;
  const [coordenadas, setCoordinates] = useState(props.coordenadas);

  const MapEvents = () => {
    if (props.readOnly) return null; // Desabilita eventos no modo readonly
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMapEvents({
      click(e) {
        setCoordinates({
          latitude: e.latlng.lat + "",
          longitude: e.latlng.lng + "",
        });
        updateForm({
          latitude: e.latlng.lat + "",
          longitude: e.latlng.lng + "",
        });
      },
    });
    return false;
  };
  useEffect(() => {
    delete leaflet.Icon.Default.prototype._getIconUrl;
    leaflet.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    if (!props.coordenadas.latitude || !props.coordenadas.longitude) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  return (
    // coordenadas &&
    <div className="map-container border">
      <MapContainer
        center={[coordenadas.latitude || "-29.726222319395504", coordenadas.longitude || "-53.09674415753518"]}
        zoom={6}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapEvents />
        {coordenadas.longitude && coordenadas.latitude ? (
          <Marker position={[coordenadas.latitude, coordenadas.longitude]}>
            <Popup>
              latitude:{coordenadas.latitude}
              <br />
              longitude:
              {coordenadas.longitude}
              <br />
              <Link to="/table">
                <button>Visualizar</button>
              </Link>
            </Popup>
          </Marker>
        ) : null}
      </MapContainer>
    </div>
  );
}
