import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import AuthReducer from "../auth/authReducer";
import DashboardReducer from "../dashboard/dashboardReducer";
import HistoricoReducer from "../historico/historicoReducer";
import TabReducer from "../common/tab/tabReducer";
import UsuarioReducer from "../usuario/usuarioReducer";
import DispositivoReducer from "../dispositivo/dispositivoReducer";
import AlertaReducer from "../alerta/alertaReducer";
import PressaoDoencasReducer from "../pressaoDoencas/pressaoDoencasReducer";
import DispositivoUsuarioReducer from "../dispositivoUsuario/dispositivoUsuarioReducer";

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	usuario: UsuarioReducer,
	dispositivo: DispositivoReducer,
	historico: HistoricoReducer,
	alerta: AlertaReducer,
	pressaoDoencas: PressaoDoencasReducer,
	dispositivoUsuario: DispositivoUsuarioReducer,
	tab: TabReducer,
	form: formReducer,
	toastr: toastrReducer,
});

export default rootReducer;
