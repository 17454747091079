import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Dashboard from "../dashboard/dashboard";
import Historico from "../historico/historico";
import UsuarioAlterarSenha from "../usuario/usuarioAlterarSenha";
import UsuarioAlterarTelefone from "../usuario/usuarioAlterarTelefone";
import Dispositivo from "../dispositivo/dispositivo";
import DispositivoUsuarioNovo from "../dispositivoUsuario/dispositivoUsuarioNovo";
import AlertaForm from "../alerta/alertaForm";
import PressaoDoencas from "../pressaoDoencas/pressaoDoencas";

export default () => (
	<div style={{ paddingTop: 64 }}>
		<Switch>
			<Route exact path="/" component={Dashboard} />
			<Route path="/alerta" component={AlertaForm} />
			<Route path="/pressaoDoencas" component={PressaoDoencas} />
			<Route path="/historico" component={Historico} />
			<Route path="/dispositivoUsuarioNovo" component={DispositivoUsuarioNovo} />
			<Route path="/dispositivo" component={Dispositivo} />
			<Route path="/usuarioAlterarSenha" component={UsuarioAlterarSenha} />
			<Route path="/usuarioAlterarTelefone" component={UsuarioAlterarTelefone} />
			<Redirect from="*" to="/" />
		</Switch>
	</div>
);
